<template>
  <div :class="{'px-6': $vuetify.breakpoint.smAndDown, 'px-16': $vuetify.breakpoint.mdAndUp}" name="contacto" id="contacto" fixed>
      <v-card
          class="rounded-xl py-6 pt-8"
          flat
          tile
          color="#F3F7F8"
          elevation="4"
      >
        <v-row class="mx-2" align="center">
          <v-col cols="12" md="3" sm="12" align="center" justify="center">
            <v-img
              :aspect-ratio="16/9"
              max-width="160px"
              src='/img/logo.svg'
            />
          </v-col>
          <v-col cols="12" md="4" sm="12" align="center">
            <v-btn
              v-for="item in dataItems"
              :key="item.icon"
              :href="item.link"
              target="blank"
              class="mx-4"
              icon
            >
              <v-icon color="#CB9865" size="28px"> {{item.icon}} </v-icon>
            </v-btn>
            <div class="footer-redes-text mx-4 pt-2">¡Contactame en mis redes sociales!</div>
          </v-col>
          <v-col cols="12" md="5" sm="12" align="center">
            <div class="footer-redes-text mx-4">
              <v-icon class="mr-4" color="#CB9865" size="28px">mdi-phone</v-icon>
              +54 362 4920661
            </div>
            <div class="footer-redes-text mx-4 pt-2">
              <v-icon class="mr-4" color="#CB9865" size="28px">mdi-email</v-icon>
              avalos.estudio0806@gmail.com
            </div>
          </v-col>
        </v-row>
      </v-card>

    <v-footer
        padless
        color="transparent"
    >
      <v-col
        class="text-center mt-8"
        cols="12"
      >
        <div class="footer-copyr-text">
          <v-icon class="pl-2" color="gray"> mdi-copyright </v-icon>
          Analía Avalos - Todos los derechos reservados
        </div>
      </v-col>
  </v-footer>
  </div>
</template>

<script>
  export default {
    name: 'Footer-Desktop',
    data: () => ({
      dataItems: [
        {icon:'mdi-whatsapp', link:'wa.link/hprtcs'},
        {icon:'mdi-instagram', link:'https://instagram.com/avalos.estudio/'},
        {icon:'mdi-linkedin', link:'https://linkedin.com/in/analia-avalos'},
      ],
    }),
  }
</script>
<style>
.footer-copyr-text {
  font-family: "Poppins-Medium";
  font-size: 0.8rem !important;
  color: gray;
}

.footer-redes-text {
  font-family: "Poppins-SemiBold";
  font-size: 1rem !important;
}

.round-footer {
  border-radius: 30px;
}

a, a:hover, a:focus, a:active {
      text-decoration: none;
      color: inherit;
}
</style>